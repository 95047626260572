// @ts-nocheck
import * as React from 'react';
import {forwardRef} from 'react';
import {AppBar, MenuItemLink, usePermissions, useRedirect, UserMenu, useTranslate} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import {ReactComponent as Logo} from '../assets/images/logo.svg'
import {Button} from "@material-ui/core";
import LocaleSwitcher from "./LocaleSwitcher";
import { ROLE_BUSINESS_ADMIN, ROLE_MERCHANT } from '../constants/permissions';


const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        boxShadow: 'none !important',
        position: 'fixed, top:0, left:0, right:0',
        transform: 0,
        visibility: 'visible',
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    button: {
        color: '#fff',
        border: '1px solid #fff',
        borderRadius: 20,
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#00acc1',
        },
    },
}));

const ConfigurationMenu = forwardRef((props, ref) => {
    const {permissions} = props
    const translate = useTranslate()

    return (
        <>
            {permissions === ROLE_MERCHANT ? <MenuItemLink
                ref={ref}
                to="/my-profile"
                primaryText={translate("sideBarTitles.editProfile")}

            /> : <MenuItemLink
                ref={ref}
                to="/edit-password"
                primaryText={translate("menuApp.editPassword")}

            />}
            {permissions === ROLE_MERCHANT && <MenuItemLink
                ref={ref}
                to="/api-settings"
                leftIcon={<SettingsIcon/>}
                primaryText={translate("menuApp.apiSettings")}

            />}
        </>


    );
});

const LoginButton = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();
    const translate = useTranslate();
    return (
        <Button
            style={{paddingLeft: 24, paddingRight: 24}}
            className={classes.button}
            onClick={() => redirect('/login')}
            color="primary"
        >
            {translate('menuApp.logins').toUpperCase()}
        </Button>
    )
}
const EditProfileMenu = ({permissions, ...props}) => {
    const classes = useStyles();
    const username = localStorage.getItem('username')
    return (
        <UserMenu
            icon={
                <Button
                    variant="outlined"
                    className={classes.button}
                >{username}</Button>}
            label={username}

            {...props}>
            <ConfigurationMenu permissions={permissions} {...props}/>
        </UserMenu>
    )
};


const CustomAppBar = (props) => {
    const {permissions} = usePermissions()

    const classes = useStyles();
    return (
        <AppBar
            {...props}
            elevation={1}
            className={classes.root}
            userMenu={
                (permissions === ROLE_MERCHANT || permissions === ROLE_BUSINESS_ADMIN)
                    ? <EditProfileMenu permissions={permissions}/> : <LoginButton/>
            }
        >
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo/>
            <span className={classes.spacer}/>
            <LocaleSwitcher/>
        </AppBar>
    );
};

export default CustomAppBar;
