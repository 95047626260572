// @ts-nocheck
import {
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
  Typography
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  SaveButton,
  useTranslate
} from "react-admin";
import { useStyles } from "./styles";
import { apiConfig } from "../../../config/apiConfig";

const ThemesForm = (props: any) => {
  const [error, setError] = useState();
  const [hasLogo, setHasLogo] = useState(true);
  const [hasColors, setHasColors] = useState(true);
  const [file, setFile] = useState();
  const [logo, setLogo] = useState();
  const [loading, setLoading] = useState(true);
  const [merchantTheme, setMerchantTheme] = useState();
  const [hasLogoChanged, setHasLogoChanged] = useState(false);
  const [hasColorsChanged, setHasColorsChanged] = useState(false);
  const [hasRemovedLogo, setHasRemovedLogo] = useState(false)

  const t = useTranslate();

  const classes = useStyles();
  const handleSubmit = (data) => {
    setLoading(true);
    const request = {
      method: hasColors ? "PUT" : "POST",
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
      credential: "include",
      data: data,
    };

    hasColorsChanged &&
      axios(`${apiConfig.API_URL}` + `/merchant/api/merchants/${props.record.merchantId}/color`,request)
        .then((res) => {
          res.status === 200
            ? notify("Data successfully updated")
            : notify("Error");
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          console.log(error);
          setLoading(false);
        });

    const formData = new FormData();
    formData.append("file", file);
    const requestLogo = {
      method: hasLogo ? "PUT" : "POST",
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
      credential: "include",
      data: formData,
    };

    hasLogoChanged &&
      axios(
        `${apiConfig.API_URL}` +
          `/merchant/api/merchants/${props.record.merchantId}/logo`,
        requestLogo
      )
        .then((res) => {
          res.status === 200
            ? notify("Data successfully updated")
            : notify("Error");
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });

        const requestDeleteLogo = {
          method: "DELETE",
          withCredentials: true,
          headers: { "Content-Type": "application/json" },
          credential: "include"
        };

       hasRemovedLogo && axios(
          `${apiConfig.API_URL}` +
            `/merchant/api/merchants/${props.record.merchantId}/logo`,
            requestDeleteLogo
        )
          .then((res) => {
            res.status === 200
              ? notify("Data successfully updated")
              : notify("Error");
            setHasRemovedLogo(false)
            setLoading(false);
          })
          .catch((error) => {
            setError(error);
            console.log(error);
            setLoading(false);
          });
  };

  useEffect(() => {
    const request = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
    };
    // @ts-ignore
    axios(`${apiConfig.API_URL}` +`/merchant/api/merchants/${props.record.merchantId}/color`,request)
      .then(({ data }) => {
        setMerchantTheme(data);
        setHasColors(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setHasColors(false);
        setLoading(false);
      });

    const requestLogo = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      responseType: "blob",
    };
    axios(
      `${apiConfig.API_URL}` +`/merchant/api/merchants/${props.record.merchantId}/logo`,requestLogo)
      .then(({ data }) => {
        setLogo(URL.createObjectURL(data));
        setHasLogo(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setHasLogo(false);
        setLogo("");
        setLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    setMerchantTheme({ ...merchantTheme, [e.target.name]: e.target.value });
    setHasColorsChanged(true);
  };
  const handleFileChange = (e) => {
    setLogo(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
    setHasLogoChanged(true);
  };

  const handleDeleteLogo = () => {
    setLogo("")
    setHasRemovedLogo(true)
  }

  return (
    <>
      {!loading ? (
        <>
          <Box sx={{ paddingX: "2.5%" }}>
            <Box sx={{ marginBottom: 10 }}>
              <Typography variant="b4" className={classes.title}>
                {t("resources.merchants.forms.payouts.uploadLogo")}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: 20 }}>
              <Typography className={classes.text}>
                {t("resources.merchants.forms.payouts.uploadFormatText")}
              </Typography>
            </Box>
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", maxHeight: "120px" }}>
                  <Box className={classes.fileInputWrapper}>
                    <InputLabel
                      htmlFor="input-with-icon-adornment"
                      className={classes.fileInputLabel}
                    >
                      <Box sx={{ textAlign: "center" }}>
                        <Typography>
                          {t(
                            "resources.merchants.forms.payouts.clickToSelectFile"
                          )}
                        </Typography>
                        <Typography style={{ fontStyle: "italic" }}>
                          {t(
                            "resources.merchants.forms.payouts.supportedFilesText"
                          )}
                        </Typography>
                      </Box>
                      <TextField
                        id="input-with-icon-adornment"
                        type="file"
                        className={classes.inputFileMedium}
                        InputLabelProps={{
                          className: classes.fileInputLabelProps,
                        }}
                        inputProps={{
                          accept: "image/png, image/jpeg",
                        }}
                        onChange={handleFileChange}
                      />
                    </InputLabel>
                  </Box>
                  <Box className={classes.imagePreview}>
                    {logo && <Box onClick={handleDeleteLogo} className={classes.cancelIcon}><CancelIcon /></Box>}
                    {logo ? (
                      <img
                        src={logo}
                        width="100%"
                        height="100%"
                        alt="logo image"
                      />
                    ) : (
                      <Typography>
                        {t("resources.merchants.forms.payouts.previewImage")}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ marginBottom: 10 }}>
              <Typography variant="b4" className={classes.title}>
                {t("resources.merchants.forms.payouts.configureTheme")}
              </Typography>
            </Box>
            <Typography className={classes.text}>
              {t("resources.merchants.forms.payouts.configureThemeText")}
            </Typography>
            <Grid container spacing={6} style={{ width: "100%" }}>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  label={t(
                    "resources.merchants.forms.payouts.headerBackgroundColor"
                  )}
                  resource={"merchants"}
                  source="headerColor"
                  name="headerColor"
                  type="color"
                  value={merchantTheme?.headerColor}
                  onChange={handleInputChange}
                  defaultValue={merchantTheme?.headerColor}
                  size="medium"
                  placeholder={t("resources.merchants.forms.payouts.pickColor")}
                  className={classes.inputMedium}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  label={t("resources.merchants.forms.payouts.primaryColor")}
                  source="primaryColor"
                  name="primaryColor"
                  defaultValue={merchantTheme?.primaryColor}
                  value={merchantTheme?.primaryColor}
                  size="medium"
                  type="color"
                  onChange={handleInputChange}
                  placeholder={t("resources.merchants.forms.payouts.pickColor")}
                  className={classes.inputMedium}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  label={t("resources.merchants.forms.payouts.secondaryColor")}
                  source="secondaryColor"
                  name="secondaryColor"
                  value={merchantTheme?.secondaryColor}
                  defaultValue={merchantTheme?.secondaryColor}
                  type="color"
                  onChange={handleInputChange}
                  size="medium"
                  placeholder={t("resources.merchants.forms.payouts.pickColor")}
                  className={classes.inputMedium}
                />
              </Grid>
            </Grid>
          </Box>
          <Grid className={classes.toolBar}>
            <SaveButton
              handleSubmitWithRedirect={() => handleSubmit(merchantTheme)}
            >
              Submit
            </SaveButton>
          </Grid>
        </>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default ThemesForm;
