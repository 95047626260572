// @ts-nocheck
import * as React from "react";
import {
  Show,
  SimpleForm,
  TextField,
  useRedirect,
  useTranslate,
  Toolbar,
} from "react-admin";
import { SectionTitle } from "../../../components";
import { Box, Button, Grid } from "@material-ui/core";
import { useStyles } from "./styles";
import PayoutInformationGrid from "./PayoutInformationGrid";
import ConfirmationPayoutDialog from "./ConfirmationPayoutDialog";

const ActionShowPayout = ({ status, ...props }) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const classes = useStyles();
  return (
    <Toolbar {...props} className={classes.toolbar}>
      {status === "PENDING" && (
        <ConfirmationPayoutDialog classes={classes} {...props} />
      )}

      {status === "PENDING" && <ConfirmationPayoutDialog decline {...props} />}
      <Button
        className={classes.button}
        onClick={() => redirect("/payouts")}
        redirect={"list"}
      >
        {translate("ra.action.cancel")}
      </Button>
    </Toolbar>
  );
};

export const PayoutFormEdit = ({ status, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Show
      actions={false}
      classes={{
        root: classes.editForm,
        card: classes.paper,
      }}
      title={"resources.payouts.forms.title"}
      {...props}
    >
      <SimpleForm
        width={"100%"}
        title={"resources.payouts.forms.title"}
        toolbar={
          <ActionShowPayout status={status} handleSubmitWithRedirect={"list"} />
        }
      >
        <Grid className={classes.titleForm}>
          {translate("resources.payouts.forms.title")}
        </Grid>
        <Grid className={classes.root} container>
          <Grid className={classes.wrapperGrid}>
            <Box className={classes.wrapper}>
              <SectionTitle
                className={classes.title}
                label="resources.payouts.forms.amount"
              />
              <TextField
                source="amount"
                label={false}
                className={classes.inputMedium}
              />
            </Box>
            <Grid className={classes.rowFieldWrapper} container>
              <Box className={classes.boxWrapper} mr={2}>
                <SectionTitle
                  className={classes.title}
                  label="resources.payouts.forms.charge"
                />
                <TextField
                  source="fee"
                  label={false}
                  className={classes.inputMedium}
                />
              </Box>
              <Box className={classes.boxWrapper}>
                <SectionTitle
                  className={classes.title}
                  label="resources.payouts.forms.totalAmount"
                />
                <TextField
                  label={false}
                  source="totalAmount"
                  className={classes.inputMedium}
                />
              </Box>
            </Grid>
          </Grid>
          <PayoutInformationGrid />
          <Box>
            <SectionTitle
              className={classes.title}
              label="resources.payouts.forms.comment"
            />
            <TextField
              source="comment"
              label={false}
              multiline={true}
              rows={6}
              className={classes.inputDescription}
            />
          </Box>
        </Grid>
      </SimpleForm>
    </Show>
  );
};
