export const passwordMatch = (value: string, compareWith: string): string | undefined => {
  if (value && !compareWith) {
    return "changePasswordForm.requiredPassword";
  }
  if (value && value !== compareWith) {
    return "changePasswordForm.confirmPasswordError";
  }
  return undefined;
};

export const passwordValidation = (value: string): string | undefined => {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,20}$/;
  if (!value) {
    return "changePasswordForm.requiredPassword";
  }
  if (!passwordRegex.test(value)) {
    return "changePasswordForm.passwordCriteria";
  }
  return undefined;
};

export const passwordNoValidation = (value: string): string | undefined => {
  if (value && value.length > 0) {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W])[A-Za-z\d\W]{8,20}$/;
    if (!passwordRegex.test(value)) {
      return "changePasswordForm.passwordCriteria";
    }
  }
  return undefined;
};

export const validateNoSpecialChars = (value: string): string | undefined => {
  if (/[^\w\s]/.test(value)) {
    return "changePasswordForm.noSpecialChars";
  }
  return undefined;
};
