import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface LoginProps {
  username: string;
  password: string;
  rememberMe: string;
}

interface AuthProvider {
  login: (props: LoginProps) => Promise<void>;
  logout: () => Promise<string>;
  checkAuth: () => Promise<void>;
  checkError: (error: { status: number }) => Promise<void>;
  getPermissions: () => Promise<string | void>;
}

export const authProvider: AuthProvider = {
  login: async ({ username, password, rememberMe }: LoginProps) => {
    const fordData = new URLSearchParams();
    fordData.append("j_username", username);
    fordData.append("j_password", password);
    fordData.append("remember-me", rememberMe);
    fordData.append("submit", "true");

    const request: AxiosRequestConfig = {
      method: "POST",
      withCredentials: true,
      data: fordData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    return await axios(
      `${(window as any)._env_.REACT_APP_PROXY_BASE_URL}/merchant/api/authentication`,
      request
    )
      .then((response: AxiosResponse) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        localStorage.setItem("isAuth", "true");
        return Promise.resolve();
      })
      .catch((error) => {
        const errorMessages: { [key: number]: string } = {
          423: "Your account is locked due to too many failed login attempts. Please try again after 1 Hour",
          401: "Invalid username or password.",
        };
    
        if (error.response) {
          const { status } = error.response;
          const errorMessage = errorMessages[status] || "Unknown error occurred.";
          throw new Error(errorMessage);
        } else {
          throw new Error("Network error or server is unreachable.");
        }
      });
  },

  logout: async () => {
    const request: AxiosRequestConfig = {
      withCredentials: true,
      method: "POST",
    };

    return axios(
      `${(window as any)._env_.REACT_APP_PROXY_BASE_URL}/merchant/api/logout`,
      request
    ).then((response: AxiosResponse) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      localStorage.removeItem("isAuth");
      localStorage.removeItem("username");
      return Promise.resolve("/login");
    });
  },

  checkAuth: () =>
    localStorage.getItem("isAuth") === "true"
      ? Promise.resolve()
      : Promise.reject(),

  checkError: (error: { status: number }) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.setItem("isAuth", "false");
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    const request: AxiosRequestConfig = {
      method: "GET",
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };

    return axios(
      `${(window as any)._env_.REACT_APP_PROXY_BASE_URL}/merchant/api/account`,
      request
    )
      .then((response: AxiosResponse) => {
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject();
        }
        if (response.data.activated === false) {
          localStorage.setItem("isAuth", "false");
          window.location.replace("#/login");
          return Promise.reject();
        }
        localStorage.setItem("langKey", response.data.langKey);
        localStorage.setItem("username", response.data.login);
        return Promise.resolve(response.data.roles[0]);
      })
      .catch(() => {
        return Promise.resolve([]);
      });
  },
};