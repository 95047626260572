// @ts-nocheck
import * as React from "react";
import {
    ArrayInput,
    Create,
    Edit,
    FormTab,
    required,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput
} from 'react-admin';
import {Box, Grid} from "@material-ui/core";
import {supportedCurrencies} from "../../../helpers/choices";
import {useStyles} from "./styles";
import { CustomToolbar, SectionTitle } from '../../../components';

export const EditPresetForm = (props: any) => {
    const classes = useStyles()

    return (
        <Edit title={"resources.payments.editTitle"}
              classes={{
                  root: classes.editForm,
                  card: classes.paper,
              }}
              {...props} >
            <TabbedForm
                classes={{root:classes.tab}}
                title={"resources.payments.editTitle"}
                syncWithLocation={false}
                toolbar={<CustomToolbar />}
            >
                <FormTab className={classes.tab}  label="resources.payments.editTitle">
                    <Grid className={classes.root} container flexDirection='column'>
                        <Grid className={classes.wrapper}>
                            <Box mr={2}>
                                <SectionTitle
                                    className={classes.title}
                                    label="resources.payments.fields.currency"/>
                                <SelectInput
                                    source="currency"
                                    label={false}
                                    choices={supportedCurrencies}
                                    optionText="fullDescription"
                                    optionValue="code"
                                    className={classes.inputMedium}
                                    validate={requiredValidate}
                                />
                            </Box>
                            <Box mr={2}>
                                <SectionTitle
                                    className={classes.title}
                                    label="resources.payments.fields.name"/>
                                <TextInput
                                    source="name"
                                    label={false}
                                    placeholder={'New payout fee'}
                                    className={classes.inputMedium}
                                    validate={requiredValidate}
                                />
                            </Box>
                            <Box>
                                <SectionTitle
                                    className={classes.title}
                                    label="resources.payments.fields.description"/>
                                <TextInput
                                    label={false}
                                    source="description"
                                    multiline={true}
                                    rows={3}
                                    className={classes.inputDescription}
                                />
                            </Box>
                        </Grid>
                        <ArrayInput label="resources.payments.fields.fees" source="fees">
                            <SimpleFormIterator
                                disableReordering={true}
                                classes={{form: classes.iterator, line: classes.iteratorDone}}
                                transitionEnter={false}
                            >
                                <TextInput label="resources.payments.fields.min" source="min" classes={{root: classes.fieldGap}}
                                           className={classes.inputMedium}/>
                                <TextInput label="resources.payments.fields.max" source='max' classes={{root: classes.fieldGap}}
                                           className={classes.inputMedium}/>
                                <TextInput label="resources.payments.fields.fixed" source='fixedFee'
                                           classes={{root: classes.fieldGap}} className={classes.inputMedium}/>
                                <TextInput label="resources.payments.fields.percentage" source='percentageFee'
                                           classes={{root: classes.fieldGap}} className={classes.inputMedium}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}
export const CreatePresetForm = (props) => {
    const classes = useStyles()

    return (
        <Create
            title={"resources.payments.createTitle"}
            classes={{
                root: classes.editForm,
                card: classes.paper,
            }}
            {...props} >
            <TabbedForm
                title={"resources.payments.createTitle"}
                toolbar={<CustomToolbar handleSubmitWithRedirect={'list'} />}
                syncWithLocation={false}>
                <FormTab className={classes.tab} label="resources.payments.editTitle">
                    <Grid container className={classes.root} flexDirection='column'>
                        <Grid className={classes.wrapper}>
                            <Box mr={2}>
                                <SectionTitle
                                    className={classes.title}
                                    label="resources.payments.fields.currency"/>
                                <SelectInput
                                    source="currency"
                                    optionText="fullDescription"
                                    optionValue="code"
                                    choices={supportedCurrencies}
                                    label={false}
                                    className={classes.inputMedium}
                                    validate={requiredValidate}
                                />
                            </Box>
                            <Box mr={2}>
                                <SectionTitle
                                    className={classes.title}
                                    label="resources.payments.fields.name"/>
                                <TextInput
                                    source="name"
                                    label={false}
                                    placeholder={'New payout fee'}
                                    className={classes.inputMedium}
                                    validate={requiredValidate}
                                />
                            </Box>
                            <Box>
                                <SectionTitle
                                    className={classes.title}
                                    label="resources.payments.fields.description"/>
                                <TextInput
                                    label={false}
                                    source="description"
                                    multiline={true}
                                    rows={3}
                                    className={classes.inputDescription}
                                />
                            </Box>
                        </Grid>
                        <ArrayInput label="resources.payments.fields.fees" source="fees">
                            <SimpleFormIterator
                                disableReordering={true}
                                classes={{form: classes.iterator, line: classes.iteratorDone}}>
                                <TextInput label="resources.payments.fields.min" source="min" classes={{root: classes.fieldGap}}
                                           className={classes.inputMedium}/>
                                <TextInput label="resources.payments.fields.max" source='max' classes={{root: classes.fieldGap}}
                                           className={classes.inputMedium}/>
                                <TextInput label="resources.payments.fields.fixed" source='fixedFee'
                                           classes={{root: classes.fieldGap}} className={classes.inputMedium}/>
                                <TextInput label="resources.payments.fields.percentage" source='percentageFee'
                                           classes={{root: classes.fieldGap}} className={classes.inputMedium}/>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Create>

    )
}
const requiredValidate = [required()];