import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "fit-content",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    maxWidth: 500,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px 0px 5px 5px",
    border: `1px solid ${theme.palette.primary.main}`,
  },

  header: {
    width: "100%",
    maxWidth: 500,
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    border: "1px solid #0AA5B7",
    borderBottom: "none",
    borderRadius: "5px 5px 0px 0px",
    color: theme.palette.primary.main,
  },
  headerTitle: {
    width: "inherit",
    justifyContent: "flex-start",
    textAlign: "right",
    fontFamily: "IBM Plex Sans",
    fontSize: "1.25rem",
    fontWeight: 700,
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "1.125rem",
    fontWeight: 700,
    color: theme.palette.background.default,
  },

  textSubtitleWrapper: {
    width: "inherit",
    color: theme.palette.primary.main,
    justifyContent: "flex-start",
    textAlign: "right",
    fontFamily: "IBM Plex Sans",
    textTransform: "capitalize",
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  subtitleHeader: {
    fontSize: "1.6rem !important",
    fontWeight: 700,
  },
  subtitleText: {
    "& .MuiTypography-root": {
      textTransform: "capitalize !important",
      fontSize: "1.125rem",
      fontWeight: 400 + " !important",
    },
  },
  text: {
    fontFamily: "IBM Plex Sans",
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: "1.5rem",
    color: theme.palette.background.default,
  },
  textWrapper: {},
  amountBox: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
}));
