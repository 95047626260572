// @ts-nocheck
import * as React from "react";
import {
    Datagrid,
    DateField,
    List,
    NumberField,
    Pagination,
    SelectInput,
    TextField,
    TextInput,
    usePermissions,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
// @ts-ignore
import { KeyboardDateInput } from "react-admin-date-picker";
import NumberFieldWithFormat from "../../components/NumberFieldWithFormat";
import Order from "./Order";
import { ThemeProvider } from "@material-ui/core/styles";
import { themeMUI } from "./styles";
import TranslatedField from "../../components/TranslatedField";
import { exporterCreation } from "./utils/exporterCreation";

const d = new Date();
const date =
    d.getFullYear() +
    `${d.getMonth() + 1 > 9 ? "-" : "-0"}` +
    (d.getMonth() + 1) +
    `${d.getDate() > 9 ? "-" : "-0"}` +
    d.getDate();
const d2 = new Date();
d2.setMonth(d2.getMonth() - 1);
const monthBefore =
    d2.getFullYear() +
    `${d2.getMonth() + 1 > 9 ? "-" : "-0"}` +
    (d2.getMonth() + 1) +
    `${d2.getDate() > 9 ? "-" : "-0"}` +
    d2.getDate();
d.setMonth(d.getMonth() - 1);

function getOrdersFilters(permissions: any) {
    const filters = [
        <TextInput
            source="paymentProviderName"
            label="resources.transactions.fields.provider"
        />,
        <TextInput
            source="customerPaymentAccountName"
            label="resources.transactions.fields.accountName"
        />,
        <TextInput
            source="customerPaymentAccountNumber"
            label="resources.transactions.fields.accountN"
        />,
        <TextInput
            source="totalAmount"
            label="resources.transactions.fields.amount"
        />,
        <TextInput
            source="orderExternalRefId"
            label="resources.transactions.fields.externalRefId"
        />,
        <SelectInput
            source="status"
            label="resources.transactions.fields.status"
            choices={[
                { id: "CONFIRMED", name: "resources.status.CONFIRMED" },
                { id: "FAILED", name: "resources.status.FAILED" },
                { id: "CANCELED", name: "resources.status.CANCELED" },
                {
                    id: "IN_PROGRESS",
                    name: "resources.status.IN_PROGRESS",
                },
                { id: "CREATED", name: "resources.status.CREATED" },
                {
                    id: "INITIALISED",
                    name: "resources.status.INITIALISED",
                },
                {
                    id: "EXPIRED",
                    name: "resources.status.EXPIRED",
                },
            ]}
        />,

        <KeyboardDateInput
            disableToolbar
            pickerVariant={"inline"}
            options={{ format: "dd/MM/yyyy" }}
            source="date_gte"
            label="resources.transactions.from"
            alwaysOn
        />,
        <KeyboardDateInput
            disableToolbar
            pickerVariant={"inline"}
            options={{ format: "dd/MM/yyyy" }}
            source="date_lte"
            label="resources.transactions.to"
            alwaysOn
        />,
    ];

    const adminFilters = [
        <TextInput
            source="merchantName"
            label="resources.transactions.fields.merchant"
        />,
        <TextInput
            source="merchantLId"
            label="resources.transactions.fields.merchantId"
        />,
    ];

    if (permissions === "ROLE_BUSINESS_ADMIN") {
        return filters.concat(adminFilters);
    }
    return filters;
}

const PostPagination = (props: any) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const OrderList = (props: any) => {
    const { permissions } = usePermissions();
    const ordersFilters = getOrdersFilters(permissions);
    const exporter = exporterCreation(permissions);
    const classes = useStyles();

    return (
        <ThemeProvider theme={themeMUI()}>
            <List
                {...props}
                title={"sideBarTitles.transactions"}
                filterDefaultValues={{
                    date_gte: `${monthBefore}`,
                    date_lte: `${date}`,
                }}
                pagination={<PostPagination />}
                filters={ordersFilters}
                syncWithLocation
                sort={{ field: "date", order: "ASC" }}
                exporter={exporter}
            >
                <Datagrid rowClick="expand" expand={<Order />}>
                    <DateField
                        source="date"
                        label="resources.transactions.fields.date"
                        locales={"en-GB"}
                        showTime
                    />
                    <DateField
                        source="processedDate"
                        label="resources.transactions.fields.processedDate"
                        locales={"en-GB"}
                        showTime
                    />
                    <NumberFieldWithFormat
                        source="totalAmount"
                        // @ts-ignore
                        label="resources.transactions.fields.amount"
                    />
                    <TextField
                        source="paymentProviderName"
                        label="resources.transactions.fields.provider"
                    />
                    <TextField
                        source="customerPaymentAccountName"
                        label="resources.transactions.fields.accountName"
                    />
                    <NumberField
                        headerClassName={classes.accountNumber}
                        source="customerPaymentAccountNumber"
                        label="resources.transactions.fields.accountN"
                    />
                    <TextField
                        source="orderExternalRefId"
                        label="resources.transactions.fields.externalRefId"
                    />
                    <TranslatedField
                        namespace={"resources.status"}
                        source="status"
                        label="resources.transactions.fields.status"
                    />

                    {permissions === "ROLE_BUSINESS_ADMIN" && (
                        <TextField
                            source="merchantName"
                            label="resources.transactions.fields.merchant"
                        />
                    )}
                    {permissions === "ROLE_BUSINESS_ADMIN" && (
                        <TextField
                            source="merchantLId"
                            label="resources.transactions.fields.merchantId"
                        />
                    )}
                </Datagrid>
            </List>
        </ThemeProvider>
    );
};

const useStyles = makeStyles({
    accountNumber: { textAlign: "left" },
});
