interface Props {
    id: string;
    label: string;
    key: string;
    type: string;
    align: "left" | "center" | "right" | "inherit" | "justify" | undefined;
}


const columns: Props[] = [
    {
        id: 'provider',
        label: "resources.merchants.forms.payments.provider",
        key: 'provider',
        type: 'string',
        align: "left",
    },
    {
        id: 'active',
        label: "resources.merchants.forms.payments.active",
        key: 'active',
        type: 'string',
        align: "center",
    },
    {
        id: 'commission',
        label: "resources.merchants.forms.payments.commissionPreset",
        key: 'commission',
        type: 'string',
        align: "left",
    },
];

export default columns
