import React from "react";
import { Box, Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(1.5, 0),
        maxWidth: 580,
        borderRadius: 10,
        margin: "14px auto",
        border: `1px solid ${theme.palette.primary.main}`,
    },
    title: {
        fontFamily: "IBM Plex Sans",
        fontSize: "1.25rem",
        fontWeight: 700,
    },
    text: {
        fontFamily: "IBM Plex Sans",
        fontSize: "1.25rem",
        fontWeight: 400,
        lineHeight: "1.5rem",
    },
    amountBox: {
        borderTop: `1px solid ${theme.palette.primary.main}`,
    },
}));

const OrderCardRow = ({ items, footer }:{items:any, footer: any}) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} direction="column">
            {items.map((item: any) => (
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    pl={1.5}
                    pr={1.5}
                    pb={0.5}
                >
                    <Typography
                    // @ts-ignore 
                    variant="b4" className={classes.text}>
                        {item.name}
                    </Typography>
                    <Typography 
                    // @ts-ignore 
                    variant="b4" className={classes.text}>
                        {item.value}
                    </Typography>
                </Box>
            ))}
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                className={classes.amountBox}
                pl={1.5}
                pr={1.5}
                pt={1}
            >
                <Typography 
                // @ts-ignore 
                variant="b4" className={classes.title}>
                    {footer.name}
                </Typography>
                <Typography 
                // @ts-ignore 
                variant="b4" className={classes.title}>
                    {footer.value}
                </Typography>
            </Box>
        </Grid>
    );
};

export default OrderCardRow;
