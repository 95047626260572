import React from 'react';

interface NumberFieldWithFormatProps {
    record?: { [key: string]: any };
    source: string;
}

const NumberFieldWithFormat: React.FC<NumberFieldWithFormatProps> = ({ record = {}, source }) => {
    const value = record[source];
    return (
        <span>{value !== undefined ? value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : ''}</span>
    );
}

export default NumberFieldWithFormat;