import * as React from "react";
import { Fragment } from "react";
import {
  Collapse,
  List,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ReduxState } from "react-admin";
import { useSelector } from "react-redux";
import palette from "../styles/palette";

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      paddingLeft: theme.spacing(10),
    },
  },
  sidebarIsClosed: {
    color: "#fff",
    "& a": {
      transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
      color: "#fff",
    },
  },
}));

const SubMenu = (props: any) => {
  const { handleToggle, isOpen, name, icon, children, dense, className } =
    props;
  const classes = useStyles();
  const sidebarIsOpen = useSelector(
    (state: ReduxState) => state.admin.ui.sidebarOpen
  );

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle} className={className}>
      <ListItemIcon className={classes.icon}>
        {isOpen ? (
          <ExpandMoreIcon
            style={
              sidebarIsOpen
                ? { color: palette.primary.main }
                : { color: "#FFF" }
            }
          />
        ) : (
          icon
        )}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  );

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  );
};

export default SubMenu;
