import React, {Fragment, useState} from 'react';
import {Button, Confirm, useNotify, useTranslate, useDataProvider, useRedirect} from "react-admin";
import {Typography} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';

interface ConfirmationPayoutDialogProps {
    record: any;
    decline: boolean;
    classes: any;
    resource: string;
}

export const ConfirmationPayoutDialog = ({record, decline, classes, resource, ...props}: ConfirmationPayoutDialogProps) => {
    const notify = useNotify();
    const translate = useTranslate()
    const redirect = useRedirect()

    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const method = decline ? "delete" : "update"

    const triggerPayout = () => {
        setLoading(true);
        dataProvider[method](`payouts`, !decline ? {data: {...record}} : {...record})
            .then(response => {
                setLoading(false);
                notify(`${translate("resources.payouts.payoutStatus.SUCCESSFUL")}`);
                redirect('/payouts')
                setOpen(false);

            })
            .catch(error => {
                setLoading(false);
                notify(`Comment approval error: ${error.message}`, {type: 'warning'});
            })
    }

    const rejectContent = <>
        <Typography component="span">{translate("resources.payouts.dialog.decline.content")}</Typography>
    </>

    const triggerContent = <>
        <Typography component="span">{translate("resources.payouts.dialog.trigger.content")}</Typography>
    </>
    const triggerTitle = <>
        <Typography
            component="span"
            // @ts-ignore
            variant='b5'>{translate(decline ? "resources.payouts.dialog.decline.title" : "resources.payouts.dialog.trigger.title")} {record?.externalReference}</Typography>
    </>
    return (
        <Fragment>
            {decline ? <Button
                    startIcon={<DeleteIcon/>}
                    label="resources.payouts.forms.declineButton"
                    style={{color: 'red'}}
                    redirect={'list'}
                    submitOnEnter={true}
                    onClick={handleClick}
                />
                : <Button
                    label="resources.payouts.forms.triggerButton"
                    className={classes.button}
                    redirect={'list'}
                    submitOnEnter={true}
                    onClick={handleClick}
                />}
            <Confirm
                isOpen={open}
                loading={loading}
                // @ts-ignore
                title={triggerTitle}
                content={decline ? rejectContent : triggerContent}
                onConfirm={triggerPayout}
                onClose={handleDialogClose}
            />

        </Fragment>);

};

export default ConfirmationPayoutDialog;