import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { BooleanInput, useTranslate } from "react-admin";
import palette from "../../../styles/palette";

const useStyles = makeStyles((theme) => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 65,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(8),
  },
  title: {
    fontSize: "1.425rem",
    fontWeight: 700,
    color: palette.primary.main,
  },
}));

const EditAdditionalFees = () => {
  const classes = useStyles();
  const t = useTranslate();

  return (
    <Grid container direction="row" style={{ paddingInline: 28 }}>
      <Box sx={{ display: "flex" }}>
        <BooleanInput
          label={""}
          resource={"merchants"}
          source="additionalCostEnabled"
        />
        <Box>
          <Typography className={classes.title}>
            {t("resources.merchants.forms.fees.enableLineItem")}
          </Typography>
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Typography>
              {t("resources.merchants.forms.fees.enableItemDescription")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default EditAdditionalFees;
