import React, { useEffect, useState } from "react";
import { PayoutFormEdit } from "./EditPayoutForm";
import AdminPayoutsInfoCard from "./InfoCards/AdminPayoutsInfoCard";
import { Grid } from "@material-ui/core";
import MerchantPayoutsInfoCard from "./InfoCards/MerchantPayoutsInfoCard";
import { PayoutFormCreate } from "./CreatePayoutForm";
import { useDataProvider } from "react-admin";

export const PayoutsFormEdit = (props: any) => {
  const [status, setStatus] = useState("");

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      style={{ marginTop: 50 }}
    >
      <PayoutFormEdit status={status} {...props} />
      <AdminPayoutsInfoCard setStatus={setStatus} {...props} />
    </Grid>
  );
};
export const PayoutsFormCreate = (props: any) => {
  const dataProvider = useDataProvider();
  const [userData, setUserData] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [balance, setBalance] = useState<any>();
  useEffect(() => {
    dataProvider
      .getOne("merchant/account", { id: props.merchantId })
      .then(({ data }) => {
        setUserData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        console.log(error);
        setLoading(false);
      });
    dataProvider
    // @ts-ignore
      .getOne("balance", {})
      .then(({ data }) => {
        setBalance(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        style={{ marginTop: 50 }}
      >
        <PayoutFormCreate
          balance={balance}
          userData={userData}
          loading={loading}
          {...props}
        />
        <MerchantPayoutsInfoCard
          userData={userData}
          balance={balance}
          loading={loading}
          setData={setUserData}
          {...props}
        />
      </Grid>
    </>
  );
};
