import * as React from "react";
import { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import columns from "./helpers/OrderColumns";
import { useRecordContext, useTranslate } from "react-admin";
import axios from "axios";

export const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 900,
    width: "fit-content" + " !important",
    marginTop: theme.spacing(2),
    margin: "14px auto",
  },
  root: {
    fontSize: "1.25rem",
    width: "fit-content",
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    border: "1px solid #0AA5B7",
    color: "rgba(0, 0, 0, 0.5)",
  },
  row: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: 700,
  },
  tableHeader: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    fontSize: "1.6rem",
    fontWeight: "bold",
    backgroundColor: "#C4E4E8 !important",
    textTransform: "capitalize",
    verticalAlign: "top",
    border: "1px solid #0AA5B7",
  },
}));
// @ts-ignore
const PresetGrid = ({ data, ...props }) => {
  const value = useRecordContext(props);

  const pricingId = value.pricingId;

  const translate = useTranslate();

  const [fee, setFee] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  function numberWithCommas(x: any) {
    if (x) {
      let parts = x?.toString().split(".");
      parts[0] = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return parts?.join(",");
    } else return 0;
  }

  useEffect(() => {
    const request = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    // @ts-ignore
    axios(`${(window as any)._env_.REACT_APP_PROXY_BASE_URL}` + `/merchant/api/pricings/${pricingId}`,request)
      .then(({ data }) => {
        setFee(data.fees);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  const classes = useStyles();
  return (
    <Grid
      className={classes.form}
      container
      direction="row"
      justifyContent="center"
    >
      <Table classes={{ root: classes.root }}>
        <TableHead>
          <TableRow classes={{ root: classes.row }}>
            {columns.map((column) => (
              <TableCell
                classes={{
                  root: classes.tableHeader,
                  head: classes.tableHeader,
                }}
                key={column.id}
                align={column.align}
              >
                {translate(column.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {fee?.map((row: any) => (
            <TableRow
            // @ts-ignore
              classes={{ body: classes.root, head: classes.tableHeader }}
              key={data?.id}
            >
              <TableCell
                classes={{ body: classes.root }}
                align="left"
                component="th"
                scope="row"
              >
                {numberWithCommas(row?.min)}
              </TableCell>
              <TableCell classes={{ body: classes.root }} align="left">
                {numberWithCommas(row?.max)}
              </TableCell>
              <TableCell classes={{ body: classes.root }} align="left">
                {numberWithCommas(row?.fixedFee)}
              </TableCell>
              <TableCell classes={{ body: classes.root }} align="left">
                {numberWithCommas(row?.percentageFee)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  );
};

export default PresetGrid;
