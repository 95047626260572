import React from "react";
import { FunctionField, useTranslate, FunctionFieldProps } from "react-admin";
// @ts-ignore
import get from 'lodash/get';

interface TranslatedFieldProps extends FunctionFieldProps {
    namespace: string;
}

const TranslatedField: React.FC<TranslatedFieldProps> = ({ namespace, ...props }) => {
    const translate = useTranslate();
    return (
        <FunctionField
            {...props}
            render={(record, source) => translate(`${namespace}.${get(record, source)}`)}
        />
    );
};

export default TranslatedField;