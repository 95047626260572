interface Props {
    id: string;
    label: string;
    key: string;
    type: string;
    align: "left" | "center" | "right" | "inherit" | "justify" | undefined;
}

const columns: Props[] = [
    {
        id: 'acquirer',
        label:'resources.merchants.forms.payouts.acquier',
        key: 'acquirer',
        type: 'string',
        align: "left",
    },
    {
        id: 'commission',
        label:'resources.merchants.forms.payouts.commissionPreset',
        key: 'commission',
        type: 'string',
        align: "left",
    },
];

export default columns
