import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';

interface SectionTitleProps {
    label: string;
    className?: string;
}

const SectionTitle: React.FC<SectionTitleProps> = ({ label, className }) => {
    const translate = useTranslate();

    return (
        <Typography className={className} gutterBottom>
            {translate(label)}
        </Typography>
    );
};

export default SectionTitle