import React from "react";
import { useRecordContext, useTranslate } from "react-admin";
import OrderCardRow from "./OrderCardRow";

const Order = (props: any) => {
    const order = useRecordContext(props);
    const translate = useTranslate();

    const content = {
        items: [
            { name: "Description", value: order.orderDescription },
            {
                name: translate(
                    "resources.transactions.orderCard.ID"
                ),
                value: order.orderTransactionId,
            },
            {
                name: translate("resources.transactions.orderCard.amount"),
                value: `${
                    order.currency === "XAF" ? "FCFA" : order.currency
                } ${order.totalAmount
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
            },
            {
                name: translate(
                    "resources.transactions.orderCard.serviceCharge"
                ),
                value: `${
                    order.currency === "XAF" ? "FCFA" : order.currency
                } ${order.appliedServiceCharge
                    .toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
            },
        ],
        footer: {
            name: translate("resources.transactions.orderCard.totalAmount"),
            value: `${
                order.currency === "XAF" ? "FCFA" : order.currency
            } ${order.amountPaid
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
        },
    };

    return <OrderCardRow items={content.items} footer={content.footer} />;
};

export default Order;
