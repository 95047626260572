import * as React from "react";
import {
  CreateButton,
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  Pagination,
  SearchInput,
  TextField,
  TopToolbar,
} from "react-admin";
import { ThemeProvider } from "@material-ui/core/styles";
import { themeMUI } from "./styles";
import PresetGrid from "../PresetGrid";
import palette from "../../../styles/palette";

const ordersFilters = [<SearchInput source="name" alwaysOn />];
const PostPagination = (props: any) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const AddNewPresetButton = () => (
  <CreateButton basePath="/payments" label="resources.payments.addNewPreset" />
);
const ListActions = () => {
  return (
    <TopToolbar>
      <AddNewPresetButton />
    </TopToolbar>
  );
};
export const PaymentList = (props: any) => {
  return (
    <ThemeProvider theme={themeMUI()}>
      <List
        {...props}
        title={"resources.payments.route"}
        pagination={<PostPagination />}
        filters={ordersFilters}
        exporter={false}
        actions={<ListActions {...props} />}
      >
        <Datagrid
          rowClick="expand"
          // @ts-ignore
          expand={<PresetGrid />}
        >
          <TextField source="name" />
          <EditButton
            style={{ border: "none", backgroundColor: "transparent" }}
            basePath="/payments"
            // @ts-ignore
            record={"id"}
          />
          <DeleteButton
            style={{
              border: "none",
              backgroundColor: "transparent",
              color: palette.primary.main,
            }}
          />
        </Datagrid>
      </List>
    </ThemeProvider>
  );
};
