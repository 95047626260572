import React from "react";
import Button from "@material-ui/core/Button";
import { useLocale, useSetLocale } from "react-admin";
import { Typography } from "@material-ui/core";

const LocaleSwitcher = () => {
  const locale = useLocale();
  const setLocale = useSetLocale();

  return (
    <div>
      <Button
        style={{
          paddingTop: 0,
          textAlign: "center",
          fontSize: 30,
          color: locale === "fr" ? "#C9C9C9" : "#FFFFFF",
        }}
        disabled={locale === "en"}
        onClick={() => {
          setLocale("en");
          localStorage.setItem("langKey", "en");
        }}
      >
        EN
      </Button>
      <Typography
        variant={"button"}
        style={{ fontSize: 30, color: "#FFFFFF" }}
        component={"span"}
      >
        |
      </Typography>
      <Button
        style={{
          paddingTop: 0,
          fontSize: 30,
          color: locale === "en" ? "#C9C9C9" : "#FFFFFF",
        }}
        disabled={locale === "fr"}
        onClick={() => {
          setLocale("fr");
          localStorage.setItem("langKey", "fr");
        }}
      >
        FR
      </Button>
    </div>
  );
};

export default LocaleSwitcher;
