// @ts-nocheck
import * as React from "react";
import {useEffect, useState} from "react";
import {
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    List,
    Pagination,
    SelectInput, ShowButton,
    TextField,
    TopToolbar,
    useDataProvider,
    usePermissions,
    useTranslate
} from 'react-admin';
import { KeyboardDateInput } from "react-admin-date-picker";
import {ThemeProvider} from '@material-ui/core/styles';
import {themeMUI} from "./styles";
import {Typography} from "@material-ui/core";
import NumberFieldWithFormat from "../../components/NumberFieldWithFormat";
import TranslatedField from "../../components/TranslatedField";

const d = new Date();
const date = d.getFullYear() + `${(d.getMonth() + 1) > 9 ? '-' : '-0'}` + (d.getMonth() + 1) + `${(d.getDate()) > 9 ? '-' : '-0'}` + d.getDate()
const d2 = new Date();
d2.setMonth(d2.getMonth() - 1);
const monthBefore = d2.getFullYear() + `${(d2.getMonth() + 1) > 9 ? '-' : '-0'}` + (d2.getMonth() + 1) + `${(d2.getDate()) > 9 ? '-' : '-0'}` + d2.getDate()
d.setMonth(d.getMonth() - 1);

const ordersFilters = [
    <KeyboardDateInput disableToolbar pickerVariant={'inline'} options={{ format: 'dd/MM/yyyy' }} source="date_gte" label="resources.transactions.dateAfter"  locales={'en-GB'} alwaysOn/>,
    <KeyboardDateInput  disableToolbar pickerVariant={'inline'} options={{ format: 'dd/MM/yyyy' }} source="date_lte" label="resources.transactions.dateBefore" locales={'en-GB'} alwaysOn/>,
    <SelectInput
        style={{
            borderRadius: "5px 5px 0px 0px",
        }}
        source="payoutStatus"
        choices={
            [
                {id: 'PENDING', name: 'resources.payouts.payoutStatus.PENDING'},
                {id: 'SUCCESSFUL', name: 'resources.payouts.payoutStatus.SUCCESSFUL'},
                {id: 'FAILED', name: 'resources.payouts.payoutStatus.FAILED'},
            ]
        }
        alwaysOn
    />,
];
const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const RequestPayoutCreateButton = () => (
    <CreateButton basePath="/payouts"  label={'resources.payouts.requestPayout'}/>
);

const ListActions = (props: any) => {
    const dataProvider = useDataProvider();
    const {permissions} = usePermissions();
    const [balance, setBalance] = useState();
    const translate = useTranslate();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    useEffect(() => {
        dataProvider.getOne('balance', {})
            .then(({data}) => {
                setBalance(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);
    return (
        <TopToolbar>
            {permissions === 'ROLE_MERCHANT' ?
                <>
                    <Typography
                        style={{paddingRight: '30px'}}
                        variant={'subtitle1'}>
                        {translate('resources.payouts.currentBalance')} <Typography
                        variant={'subtitle1'}
                        color={'textPrimary'}
                        component={'span'}>
                        {balance?.currency === "XAF" ? "FCFA" : balance?.currency} {balance?.balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </Typography>
                    </Typography>
                    <RequestPayoutCreateButton/>
                </> : null}
        </TopToolbar>)
};
export const PayoutsList = props => {
    const {permissions} = usePermissions()
    return (

        <ThemeProvider theme={themeMUI()}>
            <List {...props}
                title={'resources.payouts.fields.title'}
                  pagination={<PostPagination/>}
                  filters={ordersFilters}
                  filterDefaultValues={
                      {
                          date_gte: `${monthBefore}`,
                          date_lte: `${date}`,
                      }
                  }
                  sort={{field: 'date', order: 'ASC'}}
                  exporter={false}
                  actions={<ListActions {...props}/>}
            >
                <>
                    <Datagrid>
                        <TextField source="externalReference"/>
                        <DateField source="date" locales={'en-GB'}/>
                        <TextField source="merchantName"/>
                        <NumberFieldWithFormat source="totalAmount"/>
                        <NumberFieldWithFormat source="fee"/>
                        <TextField source="comment"/>
                        {permissions === 'ROLE_BUSINESS_ADMIN' ? <ShowButton
                            style={{
                                border: 'none',
                                backgroundColor: 'transparent',
                            }}
                            label={'show'}
                            basePath="/payouts"
                            record={"id"}
                        /> : null}
                        <TranslatedField namespace={"resources.payouts.payoutStatus"} source="payoutStatus"/>
                    </Datagrid>
                </>
            </List>
        </ThemeProvider>

    )
};