import { Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import * as React from 'react';
import {
    PasswordInput,
    SimpleForm,
    useNotify,
    useRedirect,
    useTranslate
} from 'react-admin';
// @ts-ignore
import { useLocation } from "react-router";
import { CustomToolbar, SectionTitle } from '../components';
import palette from "../styles/palette";
import { passwordMatch, passwordValidation } from '../utils/validations';

const useStyles = makeStyles(theme => ({
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: `${theme.palette.primary.light} !important`,
        borderRadius: " 0px 0px 5px 5px",
        "& .MuiButton-containedPrimary": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.default,
            borderRadius: "20px",
        }
    },
    titleForm: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottom: '1px solid #0AA5B7',
        marginBottom: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        fontWeight: 700,
        color: palette.primary.main,
        fontSize: '1.5rem',
    },
    form: {
        maxWidth: 760,
        marginLeft: theme.spacing(9),
        marginTop: theme.spacing(8),
        border: " 1px solid #0AA5B7",
        borderRadius: "5px",
        "& .MuiCardContent-root": {
            padding: theme.spacing(8),
        },
    },

    newPassword: {
        width: "100%",
    },
    leftGap: {
        marginLeft: theme.spacing(2),
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%",
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
    text: {
        width: "100%",
        textAlign: "center",
        paddingBottom: theme.spacing(2.5),
        fontSize: "0.875rem",
        fontWeight: 400,
        color: theme.palette.primary.main,
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 700,
        color: palette.primary.main,
    },
    button: {
        width: '45%',
        height: 40,
        marginBottom: theme.spacing(10.5),
        marginTop: theme.spacing(1.5),
        borderRadius: 5,
        background: "linear-gradient(180deg, #0AA5B7 13.64%, #00929C 128.79%)",
        color: theme.palette.background.default,
        fontFamily: " IBM Plex Sans",
        fontWeight: "bold",
        fontSize: "0.875rem",
        "&:hover": {
            background: "linear-gradient(180deg, #0AA5B7 13.64%, #00929C 128.79%)",
        },
    },

}));

interface FormData {
    newPassword: string;
    confirmPassword: string;
}

const validatePassword = (values: FormData) => {
    const errors: { newPassword?: string, confirmPassword?: string } = {};
    if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "changePasswordForm.confirmPasswordError";
    }
    return errors
};
const ResetPassword = () => {
    const search = useLocation().search;
    const key = new URLSearchParams(search).get('key');
    const classes = useStyles();
    const notify = useNotify();
    const redirect = useRedirect();
    const translate = useTranslate()


    const handleSubmit = (values: FormData) => {
        const request = ({
            method: 'POST',
            withCredentials: true,
            credentials: 'include',
            headers: ({'Content-Type': 'application/json;charset=utf-8'}),
            data: {
                key: key?.toString(),
                newPassword: values.newPassword.toString()
            },
        });
        // @ts-ignore
        axios(`${(window as any)._env_.REACT_APP_PROXY_BASE_URL}` + '/merchant/api/account/reset_password/finish', request)
            .then(res => {
                res.status === 200 && redirect('/reset-password-apply');
            })
            .catch(err => {
                notify(`Error: ${err?.message}`,{type: 'warning'});
                redirect('/reset-password-error')
            });

    };
    return (
        <SimpleForm
            save={handleSubmit}
            validate={validatePassword}
            onSubmit={handleSubmit}
            toolbar={<CustomToolbar label='retypePasswordForm.submit'/>}
            className={classes.form}>
            <Grid className={classes.titleForm}>
                {translate('retypePasswordForm.title')}
            </Grid>
            <Grid className={classes.text}>
                {translate('retypePasswordForm.text')}</Grid>
            <Grid className={classes.wrapper}>
                <SectionTitle
                    className={classes.title}
                    label="retypePasswordForm.newPassword"
                />
                <PasswordInput
                    source="newPassword"
                    label={"retypePasswordForm.placeholderEnterYourPassword"}
                    formClassName={classes.newPassword}
                    validate={[passwordValidation]}
                />
                <SectionTitle
                    className={classes.title}
                    label="retypePasswordForm.retypePassword"
                />
                <PasswordInput
                    source="confirmPassword"
                    label={"retypePasswordForm.placeholderConfirmYourPassword"}
                    formClassName={classes.newPassword}
                    validate={(value, allValues) => passwordMatch(value, allValues?.newPassword)}
                />
            </Grid>
        </SimpleForm>
    )
};


export default ResetPassword;