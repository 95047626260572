// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import { useDataProvider, useTranslate } from "react-admin";

const AdminPayoutsInfoCard = ({ setStatus, ...props }) => {
  const dataProvider = useDataProvider();
  const [payouts, setPayouts] = useState<any>();
  const translate = useTranslate();
  const [error, setError] = useState();
  useEffect(() => {
    dataProvider
      .getOne("payouts", { id: props.id })
      .then(({ data }) => {
        setPayouts(data);
        setStatus(data.payoutStatus);
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  const classes = useStyles();
  return (
    <>
      <Grid>
        <Box className={classes.textSubtitleWrapper}>
          <Typography className={classes.subtitleHeader}>
            {translate("resources.payouts.forms.cardEdit.status")}
          </Typography>
          {payouts?.payoutStatus && (
            <Typography className={classes.subtitleText}>
              {translate(
                `resources.payouts.payoutStatus.${payouts.payoutStatus}`
              ).toUpperCase()}
            </Typography>
          )}
        </Box>
        <Grid
          container
          direction="row"
          justify="flex-end"
          className={classes.header}
        >
          <Typography alignSelf={"end"} className={classes.headerTitle}>
            {translate("resources.payouts.forms.cardEdit.details")}
          </Typography>
        </Grid>
        <Grid container className={classes.root} direction={"column"}>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>
              {translate("resources.payouts.forms.cardEdit.externalReference")}
            </Typography>
            <Typography className={classes.text}>
              {payouts?.externalReference}
            </Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>
              {translate("resources.payouts.forms.cardEdit.payoutDate")}
            </Typography>
            <Typography className={classes.text}>{payouts?.date}</Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>
              {translate("resources.payouts.forms.cardEdit.currency")}
            </Typography>
            <Typography className={classes.text}>
              {payouts?.currency}
            </Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>
              {translate("resources.payouts.forms.cardEdit.merchant")}
            </Typography>
            <Typography className={classes.text}>
              {payouts?.merchantName}
            </Typography>
          </Box>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>
              {translate("resources.payouts.forms.cardEdit.customerReference")}
            </Typography>
            <Typography className={classes.text}>
              {payouts?.customerReference}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminPayoutsInfoCard;
