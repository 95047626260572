import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import palette from "../../styles/palette";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Loading, useTranslate } from "react-admin";
import axios from "axios";
// @ts-ignore
import { useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(8),
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    marginRight: theme.spacing(4),
    fontSize: "2.5rem",
    fontWeight: 700,
    color: palette.primary.main,
  },
  text: {
    fontSize: "1.875rem",
    fontWeight: 400,
    color: palette.primary.main,
  },
}));

export const RegistrationSaved = () => {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Grid className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <CheckCircleIcon className={classes.title} />
        <Typography className={classes.title}>
          {translate("infoPage.registred")}
        </Typography>
      </Box>
      <Separator />
      <Separator />
      <Typography className={classes.text}>
        {translate("infoPage.checkEmailApproved")}
      </Typography>
    </Grid>
  );
};
export const ResetPasswordSuccess = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Grid className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <CheckCircleIcon className={classes.title} />
      </Box>
      <Separator />
      <Separator />
      <Typography className={classes.text}>
        {translate("infoPage.checkEmail")}
      </Typography>
    </Grid>
  );
};
export const ResetPasswordApply = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Grid className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <CheckCircleIcon className={classes.title} />
        <Typography className={classes.title}>
          {translate("resetPasswordApply.title")}
        </Typography>
      </Box>
      <Separator />
      <Separator />
      <Typography className={classes.text}>
        {translate("resetPasswordApply.success")}
      </Typography>
    </Grid>
  );
};
export const ResetPasswordFailed = () => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Grid className={classes.wrapper}>
      <Box className={classes.titleWrapper}>
        <CancelIcon className={classes.title} />
        <Typography className={classes.title}>
          {translate("resetPasswordApply.title")}
        </Typography>
      </Box>
      <Separator />
      <Separator />
      <Typography className={classes.text}>
        {translate("resetPasswordApply.error")}
      </Typography>
    </Grid>
  );
};
export const ActivationNote = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState<boolean>();
  const search = useLocation().search;
  const key = new URLSearchParams(search).get("key");
  useEffect(() => {
    const request = {
      method: "GET",
      withCredentials: true,
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    };
    // @ts-ignore
    axios(`${(window as any)._env_.REACT_APP_PROXY_BASE_URL}` +`/merchant/api/activate?key=${key}`,request)
      .then((res) => {
        [200, 201].includes(res.status) && setIsError(false);
        setLoading(false);
      })
      .catch((error) => {
        setIsError(true);
        console.log(error);
        setLoading(false);
      });
  }, [key]);

  if (loading) {
    return (
      <Loading
        loadingPrimary="app.page.loading"
        loadingSecondary="app.message.loading"
      />
    );
  } else {
    return (
      <Grid className={classes.wrapper}>
        <Box className={classes.titleWrapper}>
          {!isError ? (
            <CheckCircleIcon className={classes.title} />
          ) : (
            <CancelIcon className={classes.title} />
          )}
          <Typography className={classes.title}>
            {translate("activationNote.title")}
          </Typography>
        </Box>
        <Separator />
        <Separator />
        <Typography className={classes.text}>
          {!isError
            ? translate("activationNote.success")
            : translate("activationNote.error")}
        </Typography>
      </Grid>
    );
  }
};

const Separator = () => <Box pt="1em" />;
