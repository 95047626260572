import * as React from "react";
import { useEffect, useState } from "react";
import { Edit, SimpleForm, useTranslate } from "react-admin";
import ViewDetailsForm from "./ViewDetailsForm/ViewDetailsForm";
import EditPaymentForm from "./EditPaymentForm";
import EditPayoutForm from "./EditPayoutForm";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import { CustomToolbar } from "../../components";
import palette from "../../styles/palette";
import axios from "axios";
import ThemesForm from "./ThemesForm";
import EditAdditionalFees from "./EditAdditionalFees";
import { apiConfig } from '../../config/apiConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(6),
    color: theme.palette.text.secondary,
    "& .MuiTab-textColorInherit ": {
      fontFamily: "IBM Plex Sans",
      fontSize: "1.6rem",
      textTransform: "capitalize",
      paddingRight: theme.spacing(10),
    },
  },
  titleForm: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderBottom: "1px solid #0AA5B7",
    marginBottom: theme.spacing(5.5),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontWeight: 700,
    borderRadius: "5px 5px 0 0",
    color: palette.primary.main,
    fontSize: "1.5rem",
  },
  paper: {
    boxShadow: "none",
    marginTop: theme.spacing(2),
    paddingTop: 0,
  },
  paymentForm: {
    boxShadow: "none",
    padding: 0 + " !important",
  },
  tabPanel: {
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
    paddingLeft: 20,
    borderBottom: "1px solid #0AA5B7",
  },
  tab: {
    color: palette.primary.main,
    fontWeight: 700,
    textTransform: "capitalize",
    fontSize: "1.5rem",
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(2),
  },
  wrapper: {
    marginTop: theme.spacing(6),
    border: "1px solid #0AA5B7",
    borderRadius: 5,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export const EditUserFormByAdmin = (props: any) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
  };

  const [merchant, setMerchant] = useState<any>();
  const translate = useTranslate();
  useEffect(() => {
    const request = {
      method: "GET",
      withCredentials: true,
      headers: { "Content-Type": "application/json" },
    };
    // @ts-ignore
    axios(`${apiConfig.API_URL}` +`/merchant/api/merchants/search/paging?pageNr=1&pageSize=1&queryCriteria={"order":[{"attribute":"id","ascending":true}],"filter":[{"attribute":"merchantId","values":{"stringValue":["${props.id}"]}}]}`,request)
      .then((data) => {
        setMerchant(data.data.bundles[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const classes = useStyles();

  return (
    <Grid
      style={{ marginTop: 40, border: "1px solid #0AA5B7", borderRadius: 5 }}
    >
      <Grid className={classes.titleForm}>
        {merchant?.contactPersonFirstName}
      </Grid>
      <Tabs
        className={classes.tabPanel}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="disabled tabs example"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          className={classes.tab}
          label={`${translate("resources.merchants.forms.viewDetails")}`}
        />
        <Tab
          className={classes.tab}
          label={`${translate("resources.merchants.forms.editPayment")}`}
        />
        <Tab
          className={classes.tab}
          label={`${translate("resources.merchants.forms.editPayout")}`}
        />
        <Tab
          className={classes.tab}
          label={`${translate("resources.merchants.forms.additionalFees")}`}
        />
        {/* <Tab className={classes.tab} label={`${translate("resources.merchants.forms.editPayoutMethod")}`}/> */}
        <Tab
          className={classes.tab}
          label={`${translate("resources.merchants.forms.themes")}`}
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Edit
          classes={{
            card: classes.paper,
          }}
          undoable={false}
          title={`${translate("resources.merchants.forms.editMerchant")}`}
          {...props}
        >
          <SimpleForm
            {...props}
            toolbar={<CustomToolbar 
              // @ts-ignore
              handleSubmitWithRedirect={"list"} />}
          >
            <ViewDetailsForm />
          </SimpleForm>
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Edit
          classes={{
            card: classes.paper,
          }}
          undoable={false}
          title={`${translate("resources.merchants.forms.editPayment")}`}
          {...props}
        >
          <SimpleForm
            component={Grid}
            toolbar={false}
            className={classes.paymentForm}
            {...props}
          >
            <EditPaymentForm />
          </SimpleForm>
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Edit
          classes={{
            card: classes.paper,
          }}
          title={`${translate("resources.merchants.forms.editPayout")}`}
          {...props}
        >
          <SimpleForm
            component={Grid}
            toolbar={false}
            className={classes.paymentForm}
            {...props}
          >
            <EditPayoutForm />
          </SimpleForm>
        </Edit>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Edit
          classes={{
            card: classes.paper,
          }}
          undoable={false}
          title={`${translate("resources.merchants.forms.additionalFees")}`}
          {...props}
        >
          <SimpleForm
            {...props}
            toolbar={<CustomToolbar 
              // @ts-ignore
              handleSubmitWithRedirect={"list"} />}
          >
            <EditAdditionalFees />
          </SimpleForm>
        </Edit>
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
                <Edit
                    classes={{
                        card: classes.paper,
                    }}

                    title={`${translate("resources.merchants.forms.editPayoutMethod")}`} {...props}>
                    <SimpleForm
                        component={Grid}
                        toolbar={false}
                        className={classes.paymentForm}{...props}>
                        <EditPayoutMethodForm/>
                    </SimpleForm>
                </Edit>
            </TabPanel> */}
      <TabPanel value={value} index={4}>
        <Edit
          classes={{
            card: classes.paper,
          }}
          title={`${translate("resources.merchants.forms.themes")}`}
          {...props}
        >
          <SimpleForm
            component={Grid}
            toolbar={false}
            className={classes.paymentForm}
            {...props}
          >
            <ThemesForm />
          </SimpleForm>
        </Edit>
      </TabPanel>
    </Grid>
  );
};
