import * as React from "react";
import {Fragment, useState} from "react";
import {Button, Confirm, useDataProvider, useNotify, useRefresh, useTranslate} from 'react-admin';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {Typography} from "@material-ui/core";


export const ActivateButton = ({record}: {record: any}) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const notify = useNotify();
    const translate = useTranslate()
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const approve = () => {
        setLoading(true);
        dataProvider.update(`${record.merchantId}/${record.activated ? "deactivate" : "activate"}`, {...record})
            .then(response => {
                setLoading(false);
                refresh();
                notify(`${translate("resources.merchants.fields.login")} ${record.activated ? `${translate("resources.merchants.fields.deactivation")}` : `${translate("resources.merchants.fields.activation")}`}`);
                setOpen(false);
            })
            .catch(error => {
                setLoading(false);
                notify(`Comment approval error: ${error.message}`, {type: 'warning'});
            })
    };
    const activate = <>
        <Typography component="span">{translate("resources.merchants.fields.confirmation")}</Typography>
        <Typography component="span">{" "}{translate("resources.merchants.fields.active")}</Typography>
        <Typography component="span"> {translate("resources.merchants.fields.merchant")} {record.name}?</Typography>
    </>

    const deactivate = <>
        <Typography component="span">{translate("resources.merchants.fields.confirmation")}</Typography>
        <Typography component="span">{" "}{translate("resources.merchants.fields.deactive")}</Typography>
        <Typography component="span"> {translate("resources.merchants.fields.merchant")} {record.name}?</Typography>
    </>
    return (
        <Fragment>
            <Button
                variant="text"
                style={!record.activated ? {border: 'none', backgroundColor: '#96BCC0'} : {
                    border: 'none',
                    backgroundColor: 'transparent'
                }}
                label={record.activated ? `${translate("resources.merchants.fields.deactivation")}` : `${translate("resources.merchants.fields.activation")}`}
                onClick={handleClick}
            >
                <VisibilityOffIcon/>
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title="resources.merchants.fields.title"
                content={record.activated ? deactivate : activate}
                onConfirm={approve}
                onClose={handleDialogClose}
            />

        </Fragment>);
};
