// @ts-nocheck
import React from 'react';
import {Box, Grid, Typography} from "@material-ui/core";
import {useStyles} from "./styles";
import {useTranslate} from "react-admin";

interface Props{
    userData: any,
    balance: any,
    loading: boolean,
    props: any
}

const MerchantPayoutsInfoCard = (
    {
        userData,
        balance,
        loading,
        props
    }: Props) => {
const translate = useTranslate()
    const classes = useStyles()

    return (
        <>
            {!loading && userData ? <Grid>
                <Box className={classes.textSubtitleWrapper}>
                    <Typography className={classes.subtitleHeader}>
                        {translate('resources.payouts.forms.cardCreate.currentBalance')}
                    </Typography>
                    <Typography className={classes.subtitleText}>
                        {balance?.currency === "XAF" ? " FCFA" : balance?.currency} {balance?.balance.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Typography>
                </Box>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    className={classes.header}
                >
                    <Typography alignSelf={'end'} className={classes.headerTitle}>
                        {translate('resources.payouts.forms.cardCreate.details')}</Typography>
                </Grid>
                <Grid container className={classes.root} direction={'column'}>

                    <Box className={classes.wrapper}>
                        <Typography className={classes.title}>
                            {translate('resources.payouts.forms.cardCreate.merchantId')}
                        </Typography>
                        <Typography className={classes.text}>
                            {userData?.merchantLId}</Typography>
                    </Box>
                    <Box className={classes.wrapper}>
                        <Typography className={classes.title}>
                            {translate('resources.payouts.forms.cardCreate.phoneNumber')}
                        </Typography>
                        <Typography className={classes.text}>
                            {userData?.telephone}</Typography>
                    </Box>
                    <Box className={classes.wrapper}>
                        <Typography className={classes.title}>
                            {translate('resources.payouts.forms.cardCreate.address')}
                        </Typography>
                        <Typography className={classes.text}>
                            {userData?.address?.address}</Typography>
                    </Box>
                    <Box className={classes.wrapper}>
                        <Typography className={classes.title}>
                            {translate('resources.payouts.forms.cardCreate.region')}
                        </Typography>
                        <Typography className={classes.text}>
                            {`${userData?.address?.state} ${userData?.address?.city} ${userData?.address?.countryIso3Code}`}</Typography>
                    </Box>
                    <Box className={classes.wrapper}>
                        <Typography className={classes.title}>
                            {translate('resources.payouts.forms.cardCreate.email')}
                        </Typography>
                        <Typography className={classes.text}>
                            {userData?.email}</Typography>
                    </Box>
                </Grid>
            </Grid> : <></>}
        </>
    );
};


export default MerchantPayoutsInfoCard;