import * as React from 'react';
import {Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography,} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {TextField,useTranslate} from "react-admin";

export const useStyles = makeStyles(theme => ({
        form: {

            marginTop: theme.spacing(2),
            margin: "14px auto",

        },
        root: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            paddingLeft: theme.spacing(2.5),
            paddingRight: 0,
            border: '1px solid #0AA5B7',
            color: theme.palette.primary.main,
        },
        rowTitle: {
            backgroundColor: '#C4E4E8',
            paddingTop: theme.spacing(0),
            paddingBottom: theme.spacing(0),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            fontWeight: 700,
        },
        tableHeader: {
            color: theme.palette.text.secondary,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(5),
            fontSize: '1.6rem',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            verticalAlign: 'top',
            border: '1px solid #0AA5B7',
        },

    }
));

interface Props {
    loading: boolean;
    userData: any;
}

const
    PayoutInformationGrid = ({loading, userData, ...props}: Props) => {
    const classes = useStyles();
    const translate = useTranslate();
    return (
        <>
            <Grid className={classes.form} container direction='row' justifyContent='center'>
                <Table classes={{root: classes.root}}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                classes={{root: classes.tableHeader}}
                                align={'left'}
                                colSpan={2}
                            >
                                {translate("resources.payouts.forms.grid.title")}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow
                            classes={{
                                root: classes.rowTitle,
                            }}
                        >
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                align="left" component="th" scope="row">
                                {translate("resources.payouts.forms.grid.payoutType")}
                            </TableCell>
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                style={{backgroundColor: "#FFF"}} align="left">
                                <TextField source="type"/>
                                <Typography>{userData?.payoutDetails?.type}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            className={classes.rowTitle}
                        >
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                align="left" component="th" scope="row">
                                {translate("resources.payouts.forms.grid.payoutAcquirer")}
                            </TableCell>
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                style={{backgroundColor: "#FFF"}}
                                align="left">
                                <TextField source="acquirer"/>
                                <Typography>{userData?.payoutDetails?.acquirer}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            classes={{root: classes.rowTitle}}
                        >
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                align="left" component="th" scope="row">
                                {translate("resources.payouts.forms.grid.payoutAccountNumber")}
                            </TableCell>
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                style={{backgroundColor: "#FFF"}} align="left">
                                <TextField source="accountNumber"/>
                                <Typography>{userData?.payoutDetails?.accountNumber}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            className={classes.rowTitle}
                        >
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                align="left" component="th" scope="row">
                                {translate("resources.payouts.forms.grid.payoutAccountName")}
                            </TableCell>
                            <TableCell
                                classes={{
                                    root: classes.root,
                                }}
                                style={{backgroundColor: "#FFF"}} align="left">
                                <TextField source="accountName"/>
                                <Typography>{userData?.payoutDetails?.accountName}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </>

    )
};

export default PayoutInformationGrid;